<template>
    <div class="chat-window">
        <div class="chat-title">
            <p>收件箱</p>
            <p>{{total}}</p>
        </div>
        <div class="chat-container">
            <!-- <div class="table-list"> -->
            <div class="top-button">
                <el-button round
                           size="small"
                           @click="handleDelete">删除
                </el-button>
                <!-- <el-button round
                           size="small"
                           @click="handleTranspond">转发</el-button> -->
            </div>
            <el-table :data="tableData"
                      style="width: 100%"
                      @selection-change="handleSelectionChange"
                      @row-click="openDetails"
                      :row-key="getKey"
                      border>
                <el-table-column type="selection"
                                 align="center"
                                 reserve-selection
                                 width="40"></el-table-column>
                <el-table-column label="发件人"
                                 align="center"
                                 prop="addresser">
                    <template slot-scope="scope">{{scope.row.sendUsername}}</template>
                </el-table-column>
                <el-table-column label="主题"
                                 align="center"
                                 prop="title">
                    <template slot-scope="scope">
                        <span class="pointer" @click="lookView(scope.row)">{{scope.row.subject}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="时间"
                                 align="center"
                                 prop="time">
                    <template slot-scope="scope">{{scope.row.sendTime}}</template>
                </el-table-column>
            </el-table>
        </div>
        <div class="submit">
            <div class="submit-button">
                <el-button round
                           size="small"
                           @click="handleDelete">删除
                </el-button>
                <!--<el-button round-->
                <!--size="small"-->
                <!--@click="handleTranspond">转发</el-button>-->
            </div>
            <div v-if="tableData.length > 0"
                 class="pagination-container">
                <el-pagination @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total,prev, pager, next"
                               :page-size="listQuery.pageSize"
                               :current-page.sync="listQuery.pageNum"
                               :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        // 收件箱
        name: 'InboxDetail',
        components: {},
        data() {
            return {
                total: 0,
                listQuery: {
                    pageNo: 1,
                    pageSize: 10,
                },
                loginMsg: {},
                model: {},
                multipleSelection: [],
                tableData: []
            }
        },
        created() {
            if (localStorage.getItem('loginMsg')) {
                this.loginMsg = JSON.parse(localStorage.getItem('loginMsg'))
            }

            this.getEmailList()
        },
        methods: {
            // 查看邮件详情
            lookView(val) {
                this.$router.push({path: './emailDetail', query: {id: val.mailId, title: '收件箱'}})
            },
            // 跳转邮件详情信息
            openDetails(row) {
                this.$router.push({path: 'InboxDetailInfo', query: {params: row}})
            },
            // 删除
            handleDelete() {
                if (this.multipleSelection.length == 0) {
                    this.$message({
                        message: '请先选择您要删除的邮件',
                        type: 'warning'
                    });
                    return
                }
                let idArr = []
                this.multipleSelection.forEach(item => {
                    idArr.push(JSON.parse(JSON.stringify(item.mailId)))
                })
                let ids = idArr.join(',')
                this.$api.email.batchUpdateEmail({ids, type: 2, sourceType: 0}).then(res => {
                    this.getEmailList()
                    this.$message({
                        message: '删除邮件成功',
                        type: 'success'
                    });
                    this.$emit('sendOk')
                })
            },
            // 转发
            handleTranspond() {

            },
            // 表格选择
            handleSelectionChange(val) {
                this.multipleSelection = val
            },

            // 每页页数变化
            handleSizeChange(val) {
                this.listQuery.pageSize = val
                this.getEmailList()
            },
            // 当前页变化
            handleCurrentChange(val) {
                this.listQuery.pageNo = val
                this.getEmailList()
            },
            getKey(row) {
                return row.id;
            },
            //获取邮件列表
            getEmailList() {
                let params = this.listQuery
                params.memberId = this.loginMsg.memberId
                params.mailType = 3
                this.$api.email.emailList(params).then(res => {
                    console.log("邮箱列表", res)
                    this.tableData = res.result.records
                    this.total = res.result.total
                    this.$emit('updateNum', this.total)
                    this.tableData.forEach(item => {
                        this.$set(item, 'id', JSON.stringify(item.mailId))
                    })
                }).catch(err => {
                    this.$message.error(err);
                })
            }
        }
    }
</script>

<style scoped>
    .chat-window {
        min-width: 506px;
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        position: relative;
        border-left: 1px solid #dcdfe6;
    }

    .chat-window .chat-title {
        display: flex;
        align-items: center;
        height: 60px;
        font-size: 18px;
        color: #313233;
        letter-spacing: 2px;
        padding: 0 20px;
        background: #ffffff;
        box-sizing: border-box;
        border-bottom: 1px solid #dcdfe6;
    }

    .chat-window .chat-title p:nth-child(2) {
        font-size: 18px;
        color: #7b7d80;
        margin-left: 8px;
    }

    .chat-window .chat-container {
        min-height: 540px;
        height: 100%;
        width: 100%;
        /* padding-top: 20px; */
        box-sizing: border-box;
        border-bottom: 1px solid #dcdfe6;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* overflow-x: hidden;
        overflow-y: auto; */
        /* position: relative; */
    }

    .top-button {
        height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 10px;
    }

    .top-button button {
        width: 56px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .submit {
        width: 100%;
        height: 68px;
        padding-left: 10px;
        padding-right: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        /* position: absolute;
        bottom: 0; */
        border-top: 1px solid #dcdfe6;
    }

    .submit-button {
        display: flex;
        flex-direction: row;
    }

    .submit button {
        width: 56px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .pointer {
        cursor: pointer;
    }
</style>
